import React, { useState, useEffect } from "react";

const LazyLoadingImage = ({ src, alt, className }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => setLoaded(true);
    }, [src]);

    return (
        <img
            src={src}
            alt={alt}
            className={className}
            style={{ opacity: loaded ? 1 : 0, transition: "opacity 0.5s ease-in-out" }}
        />
    );
};

export default LazyLoadingImage;